<template>
    <w-dialog :fullscreen="$vuetify.breakpoint.xsOnly" max-width="60vw" :title="isCreation ? $t('sa.applications.add_app') : $t('sa.applications.update_app', {title: application?.title})" :value="dialog" @input="onInput($event)">
        <ApplicationCreationWizard ref="wizard" v-model="application" :is-creation="isCreation" @application-created="onApplicationCreated($event)" @close="close()" />
    </w-dialog>
</template>

<script>
export default {
    name: 'ApplicationCreationDialog',
    components: {
        ApplicationCreationWizard: () => ({
            component: import('@/components/Applications/ApplicationCreationWizard')
        })
    },
    provide: function () {
        return {
            service: this.service
        }
    },
    props: {
        service: {
            required: true,
            type: Object
        },
        value: {
            required: true,
            type: Boolean
        },
        app: {
            required: false,
            type: Object,
            default: () => ({
				title : null,
				description: null,
				logo: null,
				uri: null,
				type: null
			}),
        },
        isCreation: {
            required: false,
            type:Boolean,
            default: true
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value
            },
            set: function (value) {
                this.$emit('input', value)
            }
        },
        application: {
			get: function () {
				return this.app
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
    },
    watch: {
		dialog: {
			handler: function (val) {
				if (val) {
					this.$refs.wizard.saveApplicationData()
				}
			},
			immediate: true
		}
	},
    methods: {
        onApplicationCreated: function (application) {
            this.onInput(false)
            this.$emit('application-created', application)
        },
        onInput: function (dialog) {
            if (!dialog) {
                if(this.isCreation) {
                    this.$refs.wizard.reset()
                }
                this.dialog = false
            }
        },
        close: function () {
            this.dialog = false
        }
    }
}
</script>